// layout 相关样式

@headerHeight: 60px;
@sideMenuWidth: 200px;

html,
body,
#app {
  height: 100%;
}

.app-view {
  height: 100%;

  .page-header {
    height: @headerHeight;
  }

  .main-container {
    height: calc(100% - @headerHeight);
  }

  .side-menu {
    top: @headerHeight;
    left: 0;
    bottom: 0;
    width: @sideMenuWidth;
    background: url("../images/sidebar-bg.png") no-repeat !important;
    background-size: cover !important;
    overflow-y: auto;
  }

  .app-main {
    margin-left: @sideMenuWidth;
    background: #f7f8fa;
    height: 100%;
    overflow-y: auto;
    padding: 15px;
    box-sizing: border-box;

    .content-view {
      height: 100%;
      display: flex;
      flex-direction: column;

      .page-title {
      }

      .page-panel {
        flex: 1;
      }
    }
  }
}
