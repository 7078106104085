
.page-header {
  display: flex;
  background: #192a87;
  height: 60px;
  align-items: center;
  padding: 0 25px;
  justify-content: space-between;
  display: flex;
  .logo {
    display: flex;
    align-items: center;
    .title {
      font-size: 30px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 60px;
      margin-left: 15px;
    }
  }
  .header-right {
    color: #ffffff;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    div {
      margin-left: 12px;
      display: flex;
    }
    .new-alarm {
      // margin-right: 12px;
      > span {
        margin-right: 6px;
      }
      > div {
        margin-left: 4px;
        width: 60px;
        height: 24px;
        background: #ff3e3e;
        border-radius: 40px;
        cursor: pointer;
        align-items: center;
        > img {
          height: 14px;
          height: 14px;
          margin: 0 8px;
          // margin: 2px 10px;
        }
        > div {
          margin-left: 0;
        }
      }
      .time {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
      }
      .camera {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .alarm {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff3e3e;
      }
    }
    .date {
      padding: 0 13px;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
    }
    .user,
    .logout {
      cursor: pointer;
    }
    div span {
      line-height: 24px;
    }
    .icon {
      margin-right: 4px;
    }
  }
}

.important-tooltip {
  animation: imp 3s;
  animation-iteration-count: infinite;
}
