
img {
  width: 952px;
}
.footer {
  display: flex;
  justify-content: space-between;
  .footer-left {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 32px;
    > div {
      margin-right: 32px;
      > span {
        color: red;
      }
    }
  }
}
::v-deep {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }
  }
}
