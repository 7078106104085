@import url("./layout.less");
@import url("./reset/antd.less");
@import url("./reset/menu.less");

.table-wrapper {
  margin-top: 15px;
}

.html {
  img {
    max-width: 100%;
  }
}

.flex {
  display: flex;
}

[role="button"] {
  cursor: pointer;
}

/* 全局滚动条样式 */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}
*::-webkit-scrollbar-thumb {
  background: #80bfff;
  border-radius: 8px;
  box-shadow: inset 0 0 5px #80bfff;
}
*::-webkit-scrollbar-track {
  background: rgba(201, 201, 201, 0);
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(201, 201, 201, 0);
}

.transparent.page-panel {
  padding: 0;
  background: transparent;
}

.empty {
  background-color: #000;
  background-image: url("../images/video-empty.png");
  background-position: center;
  background-repeat: no-repeat;
  * {
    display: none;
  }
}

//标签的样式
.ant-select-multiple .ant-select-selection-item {
  background-color: #e8f3ff;
  border: 0;
  line-height: 24px;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  color: #165dff;
}

.login-view {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

#webpack-dev-server-client-overlay {
  display: none;
}
