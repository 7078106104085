
.table-box {
  ::v-deep {
    .ant-table-tbody {
      cursor: pointer;
    }
  }
}
// .table-box {
//   height: 400px;
//   overflow: hidden;
//   overflow: auto;
// }
